<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout column>
				<w-tabs centered icons-and-text :value="activeTab">
					<component :is="tab.component" v-for="(tab, index) in tabs" :key="index" @click="goTo(tab.link)" />
				</w-tabs>
				<v-divider />
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<router-view />
		</v-flex>
	</v-layout>
</template>

<script>
import TeamManagerModuleGuard from '@/mixins/ModulesGuards/TeamManager/TeamManagerModuleGuard'

export default {
	name: 'TeamManager',
	mixins: [TeamManagerModuleGuard],
	data: function () {
		return {
			defaultTabs: [{ component: () => import('@/components/TeamManager/UserManagementTab'), link: 'team-manager-users' }]
		}
	},
	computed: {
		activeTab: function () {
			const routeName = this.$route.name
			return this.tabs.findIndex(tab => tab.link == routeName)
		},
		additionalTabs: function () {
			let result = []
			if (this.appService.hasModule('customers')) {
				result.push({ component: () => import('@/components/TeamManager/CompanyAssignmentTab'), link: 'team-manager-companies' })
			}
			if (this.appService.hasModule('themes')) {
				result.push({ component: () => import('@/components/Themes/TeamManager/ThemeAssignmentTab'), link: 'team-manager-themes' })
			}
			return result
		},
		tabs: function () {
			return [...this.defaultTabs, ...this.additionalTabs]
		}
	},
	methods: {
		goTo: function (routeName) {
			this.appService.goTo(routeName)
		}
	}
}
</script>
